import axios from "axios";
import getStore from "../store";
import { sessionExpire } from "../actions/UserAction";

/**
 * Create an Axios Client with defaults
 */
export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";
export const PATCH = "PATCH";
export const PUT = "PUT";
const store = getStore;

/**
 * base url
 */
export const DEFAULT_BASEURL = process.env.REACT_APP_APIURL;
export const PAYTM_CALLBACK_URL =
  "https://oasis-devapi.invoicemaster.in/api/v1/users/verify-callback";

const appClient = axios.create({
  baseURL: DEFAULT_BASEURL,
  headers: {
    "Accept-Language": "en",
  },
});

appClient.interceptors.request.use(function (config) {
  if (store) {
    let reduxState = store.getState();

    if (reduxState.userData && reduxState?.userData) {
      config.headers.authorization =
        reduxState.userData?.type + " " + reduxState.userData?.token;
    }
  }

  return config;
});

/**
 * Add a response interceptor
 */
appClient.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      store.dispatch(sessionExpire);
    }
    return Promise.reject(error);
  }
);
/**
 * On Success Callback for an api
 * @param {} response
 */

const onSuccess = function (response) {
  return response;
};

/**
 * On Failure Callback on an Api
 * @param {} error
 */
const onError = function (error) {
  // console.log("Request Failed:", error);
  if (error.response) {
  } else {
    console.log("Error Message:", error.message);
  }

  return Promise.reject(error.response || error.message);
};
/**
 * Request Wrapper with base url set o _baseUrl.
 */
const appRequest = function (options) {
  console.log(options, "NetworkOPtions");
  return appClient(options).then(onSuccess).catch(onError);
};

/**
 * This was created for uploading files, to get progress update
 */
const appPost = function (url, data, config) {
  return appClient.post(url, data, config).then(onSuccess).catch(onError);
};

export { appRequest, appPost };
