import "./Home.css";
import TopBar from "../../_common/topbar/TopBar";
import Vegas from "../../_common/Vegas/Vegas";
import Footer from "../../_common/footer/Footer";
import { Loader, sample } from "../../../assets/images";
import useHome from "./useHome";
import { useLocation } from "react-router";
import { commaValidation } from "../../../utility";
import { withTranslation } from "react-i18next";

const Home = (props) => {
  const { t } = props;

  const location = useLocation();

  const { selectRoom, roomList, loader, initialRoom } = useHome(location, t);

  /**
   * render the top bar which is common for all pages
   * @returns
   */
  const renderTopBar = () => {
    return <TopBar />;
  };

  const renderFooter = () => {
    return <Footer />;
  };

  /**
   * render vegas bar
   * @returns
   */
  const renderVegasBar = () => {
    return <Vegas />;
  };

  /**
   * render main screen
   * @returns
   */
  const renderMainRoom = () => {
    return (
      <div className="main_choose flt">
        <div className="choose_room flt">{t("Choose_Your_Room")}</div>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loader} alt="loader" />
          </div>
        )}
        {loader !== "loading" && (
          <div className="main_row home_row flt">
            <div className="row room_show">
              {roomList &&
                roomList.map((data) => (
                  <div className="col-sm-4">
                    <div className="main_room_image flt">
                      <div className="room_image flt">
                        <img
                          src={
                            data?.room_image[0]
                              ? `${process.env.REACT_APP_MEDIA_DOMAIN}${data?.room_image?.[0]}`
                              : sample
                          }
                          alt="no_image"
                        />

                        <div className="main_standerd flt">
                          {data?.room_type?.[0]?.room_type
                            ? data?.room_type?.[0]?.room_type
                            : data?.room_type}
                        </div>
                      </div>
                      <ul className="main_wifi1_det flt">
                        {data &&
                          data?.amenities.map((res) => (
                            <li>
                              <img
                                src={`${process.env.REACT_APP_MEDIA_DOMAIN}${res?.amenities_image}`}
                              />
                              <span>{res?.amenities}</span>
                            </li>
                          ))}
                      </ul>
                      {new Date(location?.state?.checkindate) <
                        new Date(location?.state?.checkoutdate) &&
                      localStorage.getItem("datetimes") &&
                      localStorage.getItem("datetimes1") &&
                        <div className={data.accomodation_type == "package" ? "price_flex flt invisible" : "price_flex flt"}>
                          Available Rooms :{" "}
                          {data?.available_rooms < 0
                            ? 0
                            : data?.available_rooms}
                        </div>
                      }
                      <div className="price_flex flt">
                        <div className="main_discount flt">
                          <div className="room_rates">
                            {data?.actual_price
                              ? `₹ ${commaValidation(
                                  Number(data?.actual_price).toFixed(2)
                                )}`
                              : ""}
                              {!initialRoom && data?.actual_price && data.accomodation_type == "package" && <span className="additional_text"> /person</span>}
                          </div>
                        </div>


                       {
                        data.accomodation_type == "package" ?
                         <div className="main_select flt">
                          {initialRoom ? (
                            <button className={"select_room opacityadd"}> Select Package </button>
                          ) : (
                            <button
                            className={
                              data?.status == false
                                ? "select_room opacityadd"
                                : "select_room"
                            }
                              onClick={() =>
                                selectRoom(data?._id, data?.available_rooms)
                              }
                            > Select Package </button>
                          )}
                        </div> :
                        <div className="main_select flt">
                        {initialRoom ? (
                          <button className={"select_room opacityadd"}>
                            {t("Select_Room")}
                          </button>
                        ) : (
                          <button
                            className={
                              data?.available_rooms <= 0
                                ? "select_room opacityadd"
                                : "select_room"
                            }
                            onClick={() =>
                              selectRoom(data?._id, data?.available_rooms)
                            }
                          >
                            {t("Select_Room")}
                          </button>
                        )}
                      </div>
                       }
                        
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="main_container">
      {renderTopBar()}
      {renderVegasBar()}
      {renderMainRoom()}
      {renderFooter()}
    </div>
  );
};

export default withTranslation()(Home);
