import "./Vegas.css";
import { withTranslation } from "react-i18next";
import { loc, search } from "../../../assets/images";
import { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { HomeService } from "../../../services";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";

const Vegas = (props) => {
  const navigate = useNavigate();
  const locs = window.location.href;
  const { t } = props;
  const [datetimevalue, setdatetimevalue] = useState("");
  const [datetimevalue1, setdatetimevalue1] = useState("");
  const [checkindate, setCheckindate] = useState();
  const [checkoutdate, setCheckoutdate] = useState();
  const [checkintime, setCheckintime] = useState("");
  const [checkouttime, setCheckouttime] = useState("");
  const title = useSelector((state) => state?.titleData?.title);
  const address = useSelector((state) => state?.titleData?.address);


  useEffect(() => {
    setCheckindate("");
    setCheckoutdate("");
  }, []);

  /**
   * store value in local storage and replace the format of time to 12:00:00 format
   * @param {*} e
   */
  const onChangeValue = (e) => {
    localStorage.setItem("datetime1", e);
    setdatetimevalue(
      localStorage.getItem("datetime1")
        ? localStorage
            .getItem("datetime1")
            .replace("00:00:00 GMT+0530", "14:00:00 GMT+0530")
        : ""
    );
    convert(e);
  };

  /**
   * convert given date and time to a format
   * @param {*} str
   */
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = 12,
      minutes = ("0" + date.getMinutes()).slice(-2);
    setCheckindate([date.getFullYear(), mnth, day].join("-"));
    setCheckintime([hours, minutes].join(":"));
  }

  /**
   * search date will call only when entering both checkin and checkout date
   */
  const searchDate = () => {
    if (checkindate && checkoutdate) {
      if (localStorage.getItem("datetime1") !== null) {
        localStorage.setItem("datetimes", localStorage.getItem("datetime1"));
      }
      if (localStorage.getItem("datetime2") !== null) {
        localStorage.setItem("datetimes1", localStorage.getItem("datetime2"));
      }
      navigate(`/`, {
        state: { checkindate: checkindate, checkoutdate: checkoutdate },
      });
    }
  };

  /**
   * convert checkout date to the format which api needs
   * @param {*} str
   */
  function convertCheckout(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = 23,
      minutes = ("0" + date.getMinutes()).slice(-2);
    // var ampm = hours >= 12 ? 'PM' : 'AM';
    setCheckoutdate([date.getFullYear(), mnth, day].join("-"));
    setCheckouttime([hours, minutes].join(":"));
  }

  /**
   * on change of checkout date setting it to local storage and other functionalities
   * @param {*} e
   */
  const onChangeValue1 = (e) => {
    localStorage.setItem("datetime2", e);
    setdatetimevalue1(
      localStorage.getItem("datetime2")
        ? localStorage
            .getItem("datetime2")
            .replace("00:00:00 GMT+0530", "12:00:00 GMT+0530")
        : ""
    );
    convertCheckout(e);
  };
  /**
   * render the screen
   */
  const renderMainScreen = () => {
    return (
      <div className="international flt">
        <div className="airport">
          <div className="hotel flt mb-2">{t(title)}</div>
          <img src={loc} alt="no_image" />{" "}
          {t(address)}
        </div>
        {locs.split("/")[3] === "" && (
          <div className="right_date">
            <Datetime
              name="checkin"
              dateFormat="DD/MM/YYYY"
              onChange={onChangeValue}
              value={new Date(datetimevalue)}
              closeOnSelect={true}
              timeFormat={null}
              isValidDate={(currentDate, selectedDate) =>
                currentDate.isAfter(new Date().getTime() - 24 * 60 * 60 * 1000)
              }
              inputProps={{
                placeholder: "Check-In",
                disabled: false,
                readOnly: true,
                onKeyDown: (e) => e.preventDefault(),
              }}
            />

            <Datetime
              dateFormat="DD/MM/YYYY"
              name="checkout"
              timeFormat={null}
              onChange={onChangeValue1}
              value={new Date(datetimevalue1)}
              closeOnSelect={true}
              isValidDate={(currentDate, selectedDate) =>
                currentDate.isAfter(new Date().getTime() - 24 * 60 * 60 * 1000)
              }
              inputProps={{
                placeholder: "Check-Out",
                disabled: false,
                readOnly: true,
                onKeyDown: (e) => e.preventDefault(),
              }}
            />
            <div className={!checkindate || !checkoutdate ? "noevents" : ""}>
              <button onClick={searchDate}>
                <img src={search} alt="no_image" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };
  return <div className="main_hotel flt">{renderMainScreen()}</div>;
};

export default withTranslation()(Vegas);
