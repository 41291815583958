import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BookingDetailsService } from "../../../services";

const useThankYouHook = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const booking_id = queryParams.get("booking_id");
  const [diffDate, setDiffDate] = useState(0);
  const [bookingData, setBookingData] = useState([]);
  const [loader, setLoader] = useState("notset");

  localStorage.removeItem("service");
  localStorage.removeItem("service1");
  localStorage.removeItem("adult");
  localStorage.removeItem("amountdata1");
  localStorage.removeItem("id");
  localStorage.removeItem("rooms");
  localStorage.removeItem("children");
  localStorage.removeItem("amountdata");
  localStorage.removeItem("datetime1");
  localStorage.removeItem("datetime2");
  localStorage.removeItem("rzp_device_id");

  /**
   * get booking
   */
  useEffect(() => {
    //CallbackUrl();
    getBooking();
    // eslint-disable-next-line
  }, []);

  /**
   * get booking
   */
  const getBooking = () => {
    setLoader("loading");
    BookingDetailsService.getBookings(booking_id)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setBookingData(res?.data);
          // const date1 = new Date(res?.data?.data?.[0]?.arrival_date);
          // const date11 = moment(date1);
          // const date2 = new Date(res?.data?.data?.[0]?.departure_date);
          // const date22 = moment(date2)
          // const diffInMs = Math.abs(date22 - date11);
          setDiffDate(localStorage.getItem("stay"));
          setLoader("isfull");
        }
      })
      .catch((err) => console.log(err));
  };

  // const CallbackUrl = () => {
  //   setLoader("loading");
  //   BookingDetailsService.paymentCallback(data, unqId, name, phone)
  //     .then((res) => {
  //       console.log(res, "CallBackResponse");
  //       getBooking();
  //     })
  //     .catch((err) => console.log(err));
  // };

  /**
   * navigate to my account
   */
  const navigateToMyAccount = () => {
    navigate("/myAccount");
  };

  return {
    bookingData,
    diffDate,
    loader,
    navigateToMyAccount,
  };
};
export default useThankYouHook;
