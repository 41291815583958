import { withTranslation } from "react-i18next";
const Footer = (props) => {
  const footer = () => {
    return (
      <div class="footer flt">
        Designed and developed By Chipsy IT Services PVt. LTD.
      </div>
    );
  };
  return <>{footer()}</>;
};
export default withTranslation()(Footer);
