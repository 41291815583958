import { useNavigate } from "react-router";

const usePaymentFailedHook = () => {
  const navigate = useNavigate();
  const gotoHome = () => {
    localStorage.removeItem("datetime1");
    localStorage.removeItem("datetime2");
    localStorage.removeItem("datetimes");
    localStorage.removeItem("datetimes1");
    navigate("/");
  };
  return {
    gotoHome,
  };
};
export default usePaymentFailedHook;
