import "./tooltip.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export  const Tooltip = ({convFee , taxAmt}) => {
  return (
    <div className='tooltip_container'>
                    <InfoOutlinedIcon />
                    <div className='tooltip_inner'>
                        <table className="tooltip_table">
                            <tr>
                                <td>Tax Amount:</td>
                                <td>₹ {taxAmt}</td>
                            </tr>
                            <tr>
                                <td>Convenience Fee:</td>
                                <td>₹ {convFee ? convFee : 0}</td>
                            </tr>
                        </table>
                    </div>
                </div>
  )
}
