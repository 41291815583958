import * as axios from "../network/Network";

const config = require("../network/api.json");

class BookingDetailsService {
  /**
   * get booking id
   * @param {*} id
   * @returns
   */
  static getBooking(booking_id) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.booking,
      data: { booking_id },
    });
  }
  /**
   * make payment
   * @param {*} amount
   * @param {*} booking_id
   * @returns
   */
  static makePayment(amount, booking_id, timediff, difference, rooms , phone,name) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.payment,
      data: { amount, booking_id, timediff, difference, rooms , phone,name},
    });
  }

  /**

   * @param {*} booking_id
   * @returns
   */
  static payment_success(
    razorpay_order_id,
    booking_id,
    name,
    phone,
    amountdata
  ) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.success_payment,
      data: { razorpay_order_id, booking_id, name, phone, amountdata },
    });
  }

  static amount_calculation(arrival_date, departure_date, roomTypeId, rooms) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.amount_calculation,
      data: { arrival_date, departure_date, roomTypeId, rooms },
    });
  }

  /**
   * getBookings
   */
  static getBookings(booking_id) {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.bookings + `/${booking_id}`,
    });
  }
  static paymentCallback(data, unqId, name, phone) {
    var bodyFormData = new FormData();
    bodyFormData.append("MERC_UNQ_REF", data.MERC_UNQ_REF);
    bodyFormData.append("BANKTXNID", data.BANKTXNID);
    bodyFormData.append("CHECKSUMHASH", data.CHECKSUMHASH);
    bodyFormData.append("CURRENCY", data.CURRENCY);
    bodyFormData.append("GATEWAYNAME", data.GATEWAYNAME);
    bodyFormData.append("MID", data.MID);
    bodyFormData.append("ORDERID", data.ORDERID);
    bodyFormData.append("PAYMENTMODE", data.PAYMENTMODE);
    bodyFormData.append("RESPMSG", data.RESPMSG);
    bodyFormData.append("STATUS", data.STATUS);
    bodyFormData.append("TXNAMOUNT", data.TXNAMOUNT);
    bodyFormData.append("TXNDATE", data.TXNDATE);
    bodyFormData.append("TXNID", data.TXNID);
    bodyFormData.append("RESPCODE", data.RESPCODE);
    bodyFormData.append("UNIQUEID", unqId);
    bodyFormData.append("NAME", name);
    bodyFormData.append("MOBILE", phone);

    const result = axios.appRequest({
      method: axios.POST,
      url: axios.PAYTM_CALLBACK_URL,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }
}
export { BookingDetailsService };
