import { Loader, paymentFail } from "../../../assets/images";
import { thankuleft } from "../../../assets/images";
import { withTranslation } from "react-i18next";
import usePaymentFailedHook from "./usePaymentFailed";
import { commaValidation } from "../../../utility";
import Footer from "../../_common/footer/Footer";
import TopBar from "../../_common/topbar/TopBar";
import "./paymentfailed.css";

const PaymentFailed = (props) => {
  const { gotoHome } = usePaymentFailedHook();
  const { t } = props;

  const paymentfailedpage = () => {
    return (
      <div className="failure_main flt">
        <div className="thanku_img flt">
          <img src={paymentFail} alt="thank" />
        </div>
        <div className="booking_failed flt">{t("Payment_Failed")}</div>
        <div className="failed_reason">{t("Something_wrong")}</div>
        <div class="btn_align">
          <button className="go_home_btn" onClick={gotoHome}>
            {t("Go_Home")}
          </button>
        </div>
      </div>
    );
  };

  const renderTopBar = () => {
    return <TopBar />;
  };

  const renderFooter = () => {
    return <Footer />;
  };
  return (
    <>
      {renderTopBar()}
      {paymentfailedpage()}
      {renderFooter()}
    </>
  );
};

export default withTranslation()(PaymentFailed);
