import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions/alert.actions";
import { BookingDetailsService, UserService } from "../../../services";
import { useLocation, useNavigate } from "react-router";
import { PaytmServices } from "../../../services/PaytmServices";
//import { PaytmServices } from "../services/PaytmServices";

import {
  registration,
  updateUser,
  userLogin,
} from "../../../actions/UserAction";

const https = require("https");

const useBookingDetails = (t, locations, displayPaytmGateway) => {
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const otp1InputRef = useRef(null);
  const otp2InputRef = useRef(null);
  const otp3InputRef = useRef(null);
  const otp4InputRef = useRef(null);
  const otp5InputRef = useRef(null);
  // const dataPath = location.search.split("=")[1]?.substring(0, 24)
  const dispatch = useDispatch();
  const [bookInfo, setBookInfo] = useState([]);
  const [diffDate, setDiffDate] = useState(0);
  const [pageDisplay, setPageDisplay] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const [userData, setUserData] = useState("notSet");
  const pathArray = window.location.pathname;
  const [bookid, setBookid] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [loader, setLoader] = useState("notset");
  const [pathimg, setPathimg] = useState();
  const [navigatePreviousPage, setNavigatePreviousPage] = useState(false);
  //var navigatePreviousPage = false;
  const [adultCountData, setAdultCountData] = useState("");

  // const [paymentData, setPaymentData] = useState({
  //   token: "",
  //   order: "",
  //   mid: "",
  //   amount: "",
  // });
  // const history = useNavigate();

  // const [txnToken, setTaxToken] = useState(null);
  // const [orderIdss, setOrderId] = useState("");
  // const [paymentOrderId, setPaymentOrderId] = useState(undefined);
  // const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // initialize();

    const handleBackButton = (event) => {
      if (navigatePreviousPage == true) {
        window.Paytm.CheckoutJS.close();
        console.log(
          "User clicked the back button while on the payment gateway"
        );
        event.preventDefault();
        navigate(1);
        setNavigatePreviousPage(false);
      }
    };

    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigatePreviousPage]);

  var mydata = [];

  // const PaymentCallbackUrl = async (data, unqid, name, phone) => {
  //   // setLoader("loading");
  //   BookingDetailsService.paymentCallback(data, unqid, name, phone)
  //     .then((res) => {
  //       console.log(res, "CallBackResponse");
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const [backstate, setBackState] = useState(false);
  // console.log(location.search.split("=")[1]?.substring(0, 24))
  //   console.log(location?.state?.adultcount);
  /**
   * timer
   */
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  /**
   * get booking
   */
  useEffect(() => {
    var storedData = localStorage.getItem("adult_count");
    setAdultCountData(JSON.parse(storedData));
    if (mydata.length > 0) {
      setLoader("loading");
      BookingDetailsService.getBooking(mydata)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setLoader("isfull");
            if (res?.data?.data?.[0]?.booking_status === 1) {
            } else {
              setLoader("isempty");
              navigate("/");
            }
            setBookInfo(res?.data?.data);
            setBookid([res?.data?.data?.[0]?._id]);
            var total =
              Number(res?.data?.data?.[0]?.additional_charge) +
              Number(res?.data?.data?.[0]?.room_charge) +
              Number(res?.data?.data?.[0]?.tax_amount) -
              Number(res?.data?.data?.[0]?.discount_amount);
            setTotalAmount(total);

            localStorage.setItem("total", res?.data?.data?.[0]?.grand_total);
            setDiffDate(location?.state?.date);
            localStorage.setItem("stay", location?.state?.date);
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);
  locations?.state?.data.map((data) => mydata.push(data?._id));

  /**
   * handle change
   * @param {*} e
   */
  const handleChange = (e) => {
    //check name field and store its value
    if (e.target.name === "name") {
      if (/^[a-zA-Z ]*$/.test(e.target.value)) {
        setName(e.target.value);
      } else if (!e.target.value) {
        setName("");
      }
    }
    //check email and store
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    //check phone and handle
    if (e.target.name === "phone") {
      let phoneNUmber = e.target.value.slice(0,10);
      setPhone(phoneNUmber);
    }
  };

  /**
   * send otp
   * @param {*} e
   */
  const sendOtp = (e) => {
    e.preventDefault();
    setSubmitted(true);

    var filter = /^[0-9]{10}$/;
    // eslint-disable-next-line
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // eslint-disable-next-line
    if (name && email && phone) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("Enter_valid_Email_Address")));
      } else if (phone && !filter.test(phone)) {
        dispatch(alertActions.error(t("Enter_valid_Mobile_Number")));
      } else {
        setLoader("loading");
        UserService.send_otp(name, email, Number(phone))
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setTimeLeft(59);
              setSubmitted(false);
              setShowOtp(true);
              setLoader("isfull");
              dispatch(alertActions.success(res.data.status.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(res.data.status.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  /**
   * make payment
   * @param {*} e
   */
  const makePayment = (e) => {
    var data = e.preventDefault();
    const phone = localStorage.getItem("phone");
    const name = localStorage.getItem("name");
    setLoader("loading");
    mydata = [];
    locations?.state?.data.map((data) => mydata.push(data?._id));
    BookingDetailsService.getBooking(mydata).then((res) => {
      if (res?.data?.status?.code === 0) {
        if (res?.data?.data?.[0]?.booking_status === 1) {
          setSubmitted(true);
          if (location?.state?.actualamt && bookid) {
            setLoader("loading");
            BookingDetailsService.makePayment(
              location?.state?.actualamt,
              mydata,
              locations?.state?.timediff,
              locations?.state?.diff,
              adultCountData,
              phone,
              name
            )
              .then((res) => {
                if (res.status.code == 1) {
                  dispatch(
                    alertActions.error(
                      "Something went wrong. Please try again!!"
                    )
                  );
                } else {
                  setSubmitted(false);
                  displayPaytmGateway(res);
                  setLoader("isfull");
                }

              })
              .catch((error) => {
                setLoader("isempty");
                console.log("Errorreg", error);
              });
          } else {
            navigate("/");
          }
        }
      } else {
        dispatch(alertActions.error("Something went wrong!!"));
        setLoader("isempty");
      }
    });
  };

  /**
   * verify number
   * @param {*} e
   */
  const verifyNumber = (e) => {
    e.preventDefault();
    // setLoader("loading")
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    if (phone) {
      if (phone && !filter.test(phone)) {
        dispatch(alertActions.error(t("Enter_valid_Mobile_Number")));
      } else {
        dispatch(updateUser(phone))
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setSubmitted(false);
              setUserData("full");
              setName(res?.data?.data?.user?.name);
              setEmail(res?.data?.data?.user?.email);
              setPhone(res?.data?.data?.user?.phone);
            } else {
              setSubmitted(false);
              setUserData("not-full");
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  /**
   * verify otp
   */
  const verifyOtpsubmit = () => {
    setLoader("loading");
    setSubmit(true);

    if (otp1 && otp2 && otp3 && otp4 && otp5 && phone && name && email) {
      let otp = otp1 + otp2 + otp3 + otp4 + otp5;
      setLoader("loading");
      dispatch(registration(name, email, phone, otp))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            navigate("/");
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   *  verify mobile otp
   */
  const verifyMobileOtp = () => {
    setSubmit(true);
    if (otp1 && otp2 && otp3 && otp4 && otp5 && phone && name && email) {
      let otp = otp1 + otp2 + otp3 + otp4 + otp5;
      setLoader("loading");
      dispatch(registration(name, email, phone, otp))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * login verify
   */
  const verifyOtpLoginsubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (otp1 && otp2 && otp3 && otp4 && otp5) {
      let otp = otp1 + otp2 + otp3 + otp4 + otp5;
      setLoader("loading");
      dispatch(userLogin(phone, otp))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            navigate("/");
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  /**
   * store otp
   * @param {*} e
   */
  const storeotp = (e) => {
    if (e.target.name === "otp1") {
      setOtp1(e.target.value.slice(0, 1));
      otp2InputRef.current.focus();
    }
    if (e.target.name === "otp2") {
      setOtp2(e.target.value.slice(0, 1));
      otp3InputRef.current.focus();
    }
    if (e.target.name === "otp3") {
      setOtp3(e.target.value.slice(0, 1));
      otp4InputRef.current.focus();
    }
    if (e.target.name === "otp4") {
      setOtp4(e.target.value.slice(0, 1));
      otp5InputRef.current.focus();
    }
    if (e.target.name === "otp5") {
      setOtp5(e.target.value.slice(0, 1));
    }
  };

  // useEffect(() => {
  //   const handleBackButton = (event) => {
  //     // Check if the user is navigating away from the payment gateway

  //     // You can add your logic here to close the payment gateway or show a message to the user.
  //     // For example, you can redirect the user to a cancellation page or close the payment gateway modal.
  //     console.log("User clicked the back button while on the payment gateway");
  //   };

  //   window.addEventListener("popstate", handleBackButton);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("popstate", handleBackButton);
  //   };
  // }, []);

  /**
   * otp validation
   * @returns
   */
  const otp1valid = () => {
    if (otp1 === "") {
      return true;
    }
  };
  const otp2valid = () => {
    if (otp2 === "") {
      return true;
    }
  };
  const otp3valid = () => {
    if (otp3 === "") {
      return true;
    }
  };
  const otp4valid = () => {
    if (otp4 === "") {
      return true;
    }
  };
  const otp5valid = () => {
    if (otp5 === "") {
      return true;
    }
  };

  /**
   * resend otp
   */
  const resendOtp = () => {
    setTimeLeft(59);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");

    UserService.send_otp(name, email, phone)
      .then((res) => {
        setShowOtp(true);
        dispatch(alertActions.success(res.data.status.message));
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  const loginPage = (val) => {
    setPageDisplay(val);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setShowOtp(false);
    setPhone("");
  };

  const selectPath = (data) => {
    setPathimg(data);
  };

  /**
   * send otp login
   */
  const sendLoginOtp = () => {
    setSubmitted(true);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    var filter = /^[0-9]{10}$/;
    if (phone) {
      if (phone && !filter.test(phone)) {
        dispatch(alertActions.error(t("Enter_valid_Mobile_Number")));
      } else {
        setLoader("loading");
        UserService.send_otp_login(phone)
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setTimeLeft(59);
              setSubmitted(false);
              setShowOtp(true);
              setLoader("isfull");
              dispatch(alertActions.success(res.data.status.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(res.data.status.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  // useEffect(() => {
  //   setOrderId(paymentOrderId);

  //   let mid = "zSQGUt63506596908289"; // Merchant ID

  //   var paytmParams = {};

  //   paytmParams.body = {
  //     requestType: "Payment",
  //     mid: mid,
  //     websiteName: "DEFAULT",
  //     orderId: paymentOrderId,
  //     callbackUrl:
  //       "https://oasis-devapi.invoicemaster.in/api/v1/users/verify-callback",
  //     txnAmount: {
  //       value: 100,
  //       currency: "INR",
  //     },
  //     userInfo: {
  //       custId: "1001",
  //     },
  //     extendInfo: {
  //       mercUnqRef: "CUST_001",
  //     },
  //   };
  //   paytmParams.head = {
  //     signature: signature,
  //   };
  //   console.log("the signature", signature);

  //   var post_data = JSON.stringify(paytmParams);
  //   var options = {
  //     hostname: "securegw.paytm.in",
  //     port: 443,
  //     path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${paymentOrderId}`,
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Content-Length": post_data.length,
  //     },
  //   };

  //   var response = "";
  //   var post_req = https.request(options, function (post_res) {
  //     post_res.on("data", function (chunk) {
  //       response += chunk;
  //     });
  //     post_res.on("end", function () {
  //       setPaymentData({
  //         ...paymentData,
  //         token: txnToken,
  //         order: paymentOrderId,
  //         mid: mid,
  //         amount: totalAmount,
  //       });
  //     });
  //   });

  //   post_req.write(post_data);
  //   post_req.end();
  // }, [signature]);

  // const initialize = () => {
  //   let orderId = "";
  //   PaytmServices.getPaytmData()
  //     .then((res) => {})
  //     .catch((err) => console.log(err));
  // };

  // const displayPaytm = (data, booking_id) => {
  //   setNavigatePreviousPage(true);
  //   setLoading(true);
  //   var config = {
  //     root: "",
  //     style: {
  //       bodyBackgroundColor: "#fafafb",
  //       bodyColor: "",
  //       themeBackgroundColor: "#0FB8C9",
  //       themeColor: "#ffffff",
  //       headerBackgroundColor: "#284055",
  //       headerColor: "#ffffff",
  //       errorColor: "",
  //       successColor: "",
  //       card: {
  //         padding: "",
  //         backgroundColor: "",
  //       },
  //     },
  //     data: {
  //       orderId: data.data.body.orderid,
  //       token: data.data.body.txnToken,
  //       tokenType: "TXN_TOKEN",
  //       amount: data.data.body.amount /* update amount */,
  //     },
  //     payMode: {
  //       labels: {},
  //       filter: {
  //         exclude: [],
  //       },
  //       order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
  //     },
  //     website: "DEFAULT",
  //     flow: "DEFAULT",
  //     merchant: {
  //       mid: paymentData.mid,
  //       redirect: false,
  //     },
  //     handler: {
  //       transactionStatus: function transactionStatus(paymentStatus) {
  //         const unq = localStorage.getItem("UniqueID");
  //         const phone = localStorage.getItem("phone");
  //         const name = localStorage.getItem("name");
  //         // console.log(unq, "UniqueId");
  //         // console.log(userData, "Userdata");
  //         // console.log(paymentStatus, "Response from PAYTM");
  //         if (paymentStatus.STATUS === "TXN_SUCCESS") {
  //           PaymentCallbackUrl(paymentStatus, unq, name, phone);
  //           window.Paytm.CheckoutJS.close();
  //           window.location.href = `https://oasisbooking.invoicemaster.in/thankYou?booking_id=${booking_id?.[0]}`;
  //         } else if (paymentStatus.STATUS === "TXN_FAILURE") {
  //           PaymentCallbackUrl(paymentStatus, unq, name, phone);
  //           window.Paytm.CheckoutJS.close();
  //           setLoading(false);
  //         } else if (paymentStatus.STATUS === "PENDING") {
  //           PaymentCallbackUrl(paymentStatus, unq, name, phone);
  //           dispatch(alertActions.error(paymentStatus.RESPMSG));
  //           window.Paytm.CheckoutJS.close();
  //           setLoading(false);
  //         } else if (paymentStatus.STATUS === "TXN_PENDING") {
  //           PaymentCallbackUrl(paymentStatus, unq, name, phone);
  //           window.Paytm.CheckoutJS.close();
  //           dispatch(alertActions.error(paymentStatus.RESPMSG));
  //           setLoading(false);
  //         } else if (paymentStatus.STATUS === "CANCELLED") {
  //           PaymentCallbackUrl(paymentStatus, unq, name, phone);
  //           window.Paytm.CheckoutJS.close();
  //           dispatch(alertActions.error(paymentStatus.RESPMSG));
  //           setLoading(false);
  //         }

  //         setLoading(false);
  //       },
  //       notifyMerchant: function notifyMerchant(eventName, data) {
  //         setLoading(false);
  //       },
  //     },
  //   };

  //   if (window.Paytm && window.Paytm.CheckoutJS) {
  //     window.Paytm.CheckoutJS.init(config)
  //       .then(function onSuccess() {
  //         window.Paytm.CheckoutJS.invoke();
  //       })
  //       .catch(function onError(error) {
  //         console.log("Error => ", error);
  //       });
  //   }
  // };

  return {
    name,
    email,
    phone,
    submitted,
    showOtp,
    otp1,
    otp2,
    otp3,
    otp4,
    otp5,
    submit,
    otp1InputRef,
    otp2InputRef,
    otp3InputRef,
    otp4InputRef,
    otp5InputRef,
    bookInfo,
    diffDate,
    pageDisplay,
    totalAmount,
    userData,
    timeLeft,
    loader,
    pathimg,
    selectPath,
    makePayment,
    sendLoginOtp,
    handleChange,
    sendOtp,
    storeotp,
    otp1valid,
    otp2valid,
    otp3valid,
    otp4valid,
    otp5valid,
    verifyOtpsubmit,
    verifyOtpLoginsubmit,
    resendOtp,
    loginPage,
    verifyNumber,
    verifyMobileOtp,
  };
};
export default useBookingDetails;
