import {TitleService} from "../services/titleDataService"

export const titleFetch = () => async (dispatch) => {

    const data  = await TitleService.title_data();
    dispatch({
            type: "FETCH_TITLE_DATA",
            payload: {
                title: data?.data?.data[0]?.title,
                address: data?.data?.data[0]?.address,
                logo: data?.data?.data[0]?.logo,
            }
    });
    return await Promise.resolve(data);
};
