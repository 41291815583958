import { useSelector } from "react-redux";

export const usePaytm = () => {
  const email = useSelector((state) => state?.userData?.user?.email);
  const name = useSelector((state) => state?.userData?.user?.name);
  const phone = useSelector((state) => state?.userData?.user?.phone);


  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayPaytmGateway = async (data) => {
    console.log(data, "PaytmData");
    // Make an API request to your server to initiate payment

    // Use the HTML content received to create a new document and redirect
    const paymentWindow = window.open("", "_self");
    paymentWindow.document.open();
    paymentWindow.document.write(data.data);
    paymentWindow.document.close();
  };
  return {
    loadScript,
    displayPaytmGateway,
  };
};
