/**
 * user reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

const initialState = {
    logo : "",
    title : "",
    address :""
}
export function TitleDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_TITLE_DATA":
      return {
        ...state,
        title: payload.title,
        logo : payload.logo,
        address :  payload.address
    };
     default:
      return state;
  }
}
