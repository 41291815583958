import * as axios from "../network/Network";

const config = require("../network/api.json");

class TitleService {
 
  static title_data() {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.get_title,
    });
  }

}
export { TitleService };
