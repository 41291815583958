import TopBar from "../../_common/topbar/TopBar";
import Footer from "../../_common/footer/Footer";
import { Loader, samudhyathaImg } from "../../../assets/images";
import useBookingDetails from "../bookingDetails/useBookingDetails";
import "./login.css";
import { withTranslation } from "react-i18next";

const Login = (props) => {
  const { t } = props;
  const {
    name,
    email,
    phone,
    submitted,
    showOtp,
    otp1,
    otp2,
    otp3,
    otp4,
    otp5,
    submit,
    otp1InputRef,
    otp2InputRef,
    otp3InputRef,
    otp4InputRef,
    otp5InputRef,
    pageDisplay,
    timeLeft,
    loader,
    verifyOtpsubmit,
    handleChange,
    sendOtp,
    resendOtp,
    storeotp,
    otp1valid,
    otp2valid,
    otp3valid,
    otp4valid,
    otp5valid,
    loginPage,
    sendLoginOtp,
    verifyOtpLoginsubmit,
  } = useBookingDetails(t);

  /**
   * to check otp number is valid or not
   */
  var validotp1 = otp1valid();
  var validotp2 = otp2valid();
  var validotp3 = otp3valid();
  var validotp4 = otp4valid();
  var validotp5 = otp5valid();

  const renderTopBar = () => {
    return (
      <div className="main_login flt">
        <TopBar />
      </div>
    );
  };
  /**
   * login and booking details folder functionalities are handled together
   *
   * @returns
   */

  /**
   * login and registration page both are hancled based on single state update
   * @returns
   */
  const renderPersonalInfo = () => {
    return (
      <div className="main_row_padding flt">
        <div className="main_boxshadow flt">
          <div className="row">
            <div className="col-sm-6 ">
              <div className="main_leftside1 flt">
                <div className="login_image flt">
                  <img src={samudhyathaImg} alt="login_image" />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="main_rightside1 flt">
                <div className="login_name flt">{t("Register")}</div>
                <div className="input_names flt">
                  <div className="input_details flt">
                    <label className=" namedata flt">{t("Full_Name")}</label>
                    <input
                      type="text"
                      placeholder={t("Enter_fullname")}
                      name="name"
                      value={name}
                      onChange={handleChange}
                    />
                  </div>
                  {submitted && !name && (
                    <div className="invalid-feedback">
                      {t("Name_is_required")}
                    </div>
                  )}

                  <div className="input_details flt">
                    <label className=" namedata flt">{t("Email_Addres")}</label>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      placeholder={t("Enter_email")}
                      onChange={handleChange}
                    />
                  </div>
                  {submitted && !email && (
                    <div className="invalid-feedback">
                      {t("Email_is_required")}
                    </div>
                  )}

                  <div className="input_details_login flt">
                    <label className=" namedata flt">
                      {t("Mobile_Number")}
                    </label>
                    <div class="login_ip flt">
                      <input
                        type="number"
                        name="phone"
                        value={phone}
                        placeholder={t("Enter_mobile_no")}
                        onChange={handleChange}
                      />
                      <span>+91</span>
                    </div>
                  </div>
                  {submitted && !phone && (
                    <div className="invalid-feedback">
                      {t("Mobile_Number_is_required")}
                    </div>
                  )}
                  <div className="login_here" onClick={() => loginPage(0)}>
                    {t("Login_here")}
                  </div>
                </div>

                {showOtp === false && (
                  <div className="note flt">
                    <button onClick={sendOtp}>{t("Send_OTP")}</button>
                  </div>
                )}

                {showOtp === true && (
                  <>
                    {loader === "loading" && (
                      <div className="main_load ">
                        <img src={Loader} alt="loader" />
                      </div>
                    )}
                    {loader !== "loading" && (
                      <>
                        <div className="input_otp_details flt">
                          <label className=" namedata1 flt">
                            <div className="guest_cont">
                              {t("Enter_5_Digits")}
                            </div>
                            <div className="guest_infos">
                              <span className="timer">
                                {timeLeft ? "" + timeLeft : ""}
                              </span>
                              {timeLeft === null ? (
                                <span className="resend" onClick={resendOtp}>
                                  {t("Resend_OTP")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </label>
                          <div className="otp_data flt">
                            <input
                              type="text"
                              placeholder=""
                              name="otp1"
                              className={
                                submit && validotp1 ? "is-invalid" : ""
                              }
                              value={otp1}
                              maxLength={1}
                              onChange={storeotp}
                              ref={otp1InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp2"
                              maxLength={1}
                              className={
                                submit && validotp2 ? "is-invalid" : ""
                              }
                              value={otp2}
                              onChange={storeotp}
                              ref={otp2InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp3"
                              maxLength={1}
                              className={
                                submit && validotp3 ? "is-invalid" : ""
                              }
                              value={otp3}
                              onChange={storeotp}
                              ref={otp3InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp4"
                              maxLength={1}
                              className={
                                submit && validotp4 ? "is-invalid" : ""
                              }
                              value={otp4}
                              onChange={storeotp}
                              ref={otp4InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp5"
                              maxLength={1}
                              className={
                                submit && validotp5 ? "is-invalid" : ""
                              }
                              value={otp5}
                              onChange={storeotp}
                              ref={otp5InputRef}
                            />
                          </div>
                        </div>
                        <div className="Verfie flt">
                          <button onClick={verifyOtpsubmit}>
                            Verify and Continue
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPersonalInfo1 = () => {
    return (
      <div className="main_row_padding flt">
        <div className="main_boxshadow flt">
          <div className="row">
            <div className="col-sm-6">
              <div className="main_leftside1 flt">
                <div className="login_image flt">
                  <img src={samudhyathaImg} alt="login_image" />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="main_rightside1 flt">
                <div className="login_name flt">{t("Login")}</div>
                <div className="input_names flt">
                  <div className="input_details_login flt">
                    <label className=" namedata flt">
                      {t("Mobile_Number")}
                    </label>
                    <div class="login_ip flt">
                      <input
                        type="number"
                        name="phone"
                        placeholder={t("Enter_mobile_no")}
                        value={phone}
                        onChange={handleChange}
                      />
                      <span>+91</span>
                    </div>
                  </div>
                  {submitted && !phone && (
                    <div className="invalid-feedback">
                      {t("Mobile_Number_is_required")}
                    </div>
                  )}
                  <div className="login_here" onClick={() => loginPage(1)}>
                    {t("Register_here")}
                  </div>
                </div>

                {showOtp === false && (
                  <div className="note flt">
                    <button onClick={sendLoginOtp}>{t("Send_OTP")}</button>
                  </div>
                )}

                {showOtp === true && (
                  <>
                    {loader === "loading" && (
                      <div className="main_load ">
                        <img src={Loader} alt="loader" />
                      </div>
                    )}
                    {loader !== "loading" && (
                      <>
                        <div className="input_otp_details flt">
                          <label className=" namedata1 flt">
                            <div className="guest_cont">
                              {t("Enter_5_Digits")}
                            </div>
                            <div className="guest_infos">
                              <span className="timer">
                                {timeLeft ? "" + timeLeft : ""}
                              </span>
                              {timeLeft === null ? (
                                <span className="resend" onClick={resendOtp}>
                                  {t("Resend_OTP")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </label>
                          <div className="otp_data flt">
                            <input
                              type="text"
                              placeholder=""
                              name="otp1"
                              className={
                                submit && validotp1 ? "is-invalid" : ""
                              }
                              value={otp1}
                              onChange={storeotp}
                              ref={otp1InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp2"
                              className={
                                submit && validotp2 ? "is-invalid" : ""
                              }
                              value={otp2}
                              onChange={storeotp}
                              ref={otp2InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp3"
                              className={
                                submit && validotp3 ? "is-invalid" : ""
                              }
                              value={otp3}
                              onChange={storeotp}
                              ref={otp3InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp4"
                              className={
                                submit && validotp4 ? "is-invalid" : ""
                              }
                              value={otp4}
                              onChange={storeotp}
                              ref={otp4InputRef}
                            />
                            <input
                              type="text"
                              placeholder=""
                              name="otp5"
                              className={
                                submit && validotp5 ? "is-invalid" : ""
                              }
                              value={otp5}
                              onChange={storeotp}
                              ref={otp5InputRef}
                            />
                          </div>
                        </div>
                        <div className="Verfie flt">
                          <button onClick={verifyOtpLoginsubmit}>
                            Verify and Continue
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return <Footer />;
  };

  return (
    <>
      {renderTopBar()}
      {pageDisplay === 1 && renderPersonalInfo()}
      {pageDisplay === 0 && renderPersonalInfo1()}
      {renderFooter()}
    </>
  );
};

export default withTranslation()(Login);
